<template>
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="13" cy="13" r="13" fill="black"/>
    <line x1="12.9983" y1="22.127" x2="12.9983" y2="3.87163" stroke="white" stroke-width="1.65957"/>
    <line x1="12.9983" y1="22.127" x2="12.9983" y2="3.87163" stroke="white" stroke-width="1.65957"/>
    <line x1="12.9983" y1="22.127" x2="12.9983" y2="3.87163" stroke="white" stroke-width="1.65957"/>
    <line x1="22.1289" y1="13.5524" x2="3.87359" y2="13.5524" stroke="white" stroke-width="1.65957"/>
    <line x1="22.1289" y1="13.5524" x2="3.87359" y2="13.5524" stroke="white" stroke-width="1.65957"/>
    <line x1="22.1289" y1="13.5524" x2="3.87359" y2="13.5524" stroke="white" stroke-width="1.65957"/>
  </svg>
</template>